import { Backdrop, Box, Grid, Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import CustomRadioButton from '../CustomRadioButton'
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import CustomRadioBox from '../CustomRadioBox';
import { useState } from 'react';
// import UpIcon from '../icons/UpIcon';
// import DownIcon from '../icons/DownIcon';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';
import { afterValidate, getNamesFromIds, removeWhiteSpaces } from '../../../../utils/commonServices';
import { getErrorMsg } from '../../../../utils/validator';
import { projectTypeIcons } from '../../../../constants/constants';
import { gaEvents } from '../../../../ga/actions';

const ProjectTypePage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const [projectTypeList, setProjectTypeList] = useState(dropdownReducer?.project_type)
    const [projectTypeValidationFlag ,setProjectTypeValidationFlag] = useState(false)
    const dispatch = useDispatch()
    const isListView = commonReducer?.list_view
    const mobileView = useMediaQuery('(max-width:599px)');
    
    useEffect(()=> {
      let activeTypes = [];
      let inactiveTypes = [];
      dropdownReducer?.project_type?.map(type=>{
        if((!scriptReducer?.enable_story && type.name == 'storyboard')  || type.name == 'avatar' || type.name == 'image') {
          inactiveTypes.push(type)
        } else {
          activeTypes.push(type)
        }
      })

      setProjectTypeList([...activeTypes,...inactiveTypes])
    }, [dropdownReducer?.project_type, scriptReducer?.enable_story ])

 

      const finishedHandler = ()=> {
        validateInputs(true)
        afterValidate(nextPageHandler);
    }

    const validateInputs = (flag) => {
        setProjectTypeValidationFlag(flag)
    }

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          console.log("delete popup", scriptReducer.open_delete_popup)
         !scriptReducer.open_delete_popup && nextPageHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [createProjectReducer.commonPageData.projectTypeId, scriptReducer.open_delete_popup]);


    const handleRadioChange = (event) => {
      // setPageData((last) => {
      //   return { ...last, project_type_id: parseInt(event.target.value) }
      // })
      const projectType = getNamesFromIds(event.target.value, dropdownReducer?.project_type)?.name
      const lastId = createProjectReducer.commonPageData.projectTypeId;
      const newId = parseInt(event.target.value)
      if(newId > 3 || (lastId > 4 && newId <=3)) {
        dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, projectTypeId: parseInt(event.target.value), lengthId: "" }}))
      } else {
        dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, projectTypeId: parseInt(event.target.value)}}))
      }
      // gaEvents.clickedProjectType(projectType)
     
    };

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width: isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
          {(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:isListView ? '5vw' :'14vw', marginTop: isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}, height:'100%', overflowY:'scroll', }}>
              <Box mb={{xs:1, sm:2}}>
                <Typography sx={{fontSize:isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography  sx={{fontSize:isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} :{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>What are you creating today?</Typography>
                <Typography sx={{fontSize:isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Select a project type.</Typography>
              </Box>

              <Box className='flex' gap={1} sx={{  overflowY:'scroll'}}>
               
                <Grid container mt={isListView ? 1:3} ml={!mobileView && {xs: 3, sm: 0, md:1}}  rowSpacing={isListView ? 2 : 0} gap={{xs:1, sm: isListView ? 0 : 1.5, md: isListView ? 0 : 1.5}}  sx={{mr: mobileView && 2, justifyContent:{xs:'center', md:'flex-start'}, width:'100%', display:  {xs:'grid', md:'flex'}}}>
                  {
                    projectTypeList?.map((type, index)=>{
                      return !(contentReducer.create_content_open && type.name == 'voice over') && <Grid item  xs={12} sm={ 12 } md={5.5} lg={3.5}>
                      <CustomRadioButton
                      disabled={(!scriptReducer?.enable_story && type.name == 'storyboard') || type.name == 'avatar' || type.name == 'image' || (contentReducer.create_content_open && type.name == 'voice over')}
                      label='script'
                      id={type.id}  
                      name='projectType' 
                      handleRadioChange={handleRadioChange}
                      radioSize = {20}
                      height={isListView ? {xs:'55px',lg:'60px', xl:'80px'} :{xs:'55px',lg:'70px', xl:'103px'}}
                      width={isListView ? {xs:'255px',sm:'220px',md:'260px', lg:'225px', xl:'300px'} :{xs:'255px',sm:'220px',md:'260px', lg:'270px', xl:'381px'}}
                      selectedValue={createProjectReducer.commonPageData.projectTypeId}
                    //  setSelectedValue={setPageData}
                      component={
                      <Box className='flex a-center j-start ' sx={{width: '100%', marginLeft: {xs:"10px" ,sm:'6px', md: "10px", lg:"12px"},}} gap={2}>
                        <Box sx={{width:'20px', height:'28px'}}><img style={{height: '28px'}} src={projectTypeIcons[removeWhiteSpaces(type.name)]}/></Box>
                        <Typography sx={{color:'black', fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, textAlign: 'left', fontWeight: 600}}>{type.name}</Typography>
                      </Box>}
                    />
                  </Grid>
                    })
                  }
                    
                </Grid>
              
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:{xs:2, sm: 1, lg:3}, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <RightIcon sx={{ cursor:'pointer', fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}}  onClick={pageIndex !== totalScreens ? nextPageHandler : null}/>
          </Box>
        </Box>
    {/* </Backdrop> */}
    {/* </Slide> */}
    </>
  )
}

export default ProjectTypePage