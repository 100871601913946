
import "@fontsource/rubik";
import { Backdrop, Box, CircularProgress, Typography} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomSnackbar from "../../components/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackbarDrawer from "../../components/CustomSnackbarDrawer";
import { updateSriptState } from "../../redux/projectScriptSlice";
import CommonLoader from "../eventcreation/components/CommonLoader";
import { updateProjectCreationState } from "../../redux/projectCreationSlice";
import DeleteAccountConfirmationPopup from "../dashboard/components/DeleteConfirmationPopup";
import hotkeys from 'hotkeys-js';
import { useEffect, useState } from "react";
import DisclaimerPopup from "../unauth/components/DisclaimerPopup";
import DeleteConfirmationPopup from "../dashboard/components/DeleteConfirmationPopup";
import Footer from "../unauth/components/Footer";
import { updateUserState } from "../../redux/userSlice";

const useStyles = makeStyles((theme) => ({
    container: {
       // position: 'absolute',
       // padding: '4.5% 4.9% 6% 5.9%',
        fontFamily: 'Rubik',
        height: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
       // width: '100%',
      //  minWidth: '860px',
        // backgroundImage: 'linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
        backgroundImage: 'radial-gradient(at bottom right, #039f10, #008d65, #007593, #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display : 'none'
        },
    }
}));

const AuthLayout = (props) => {

    const classes = useStyles();
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
    const dispatch = useDispatch()
    const [openDisclaimerPopup, setOpenDisclaimerPopup] = useState(false);
    // const [pixelRatio] = useDevicePixelRatio()
    //  useEffect(()=> {
    //     console.log("pixel", pixelRatio)
    //  }, [pixelRatio])
    const snackbarProps = useSelector((state) => state.messageReducer);
    useEffect(() => {
      // Register keyboard shortcuts
      hotkeys('ctrl+shift+a,ctrl+shift+d', (event, handler) => {
        event.preventDefault();
        switch (handler.key) {
          case 'ctrl+shift+a':
            // Handle Ctrl+A
          // process.env.REACT_APP_ENV !== 'prod' && dispatch(updateSriptState({enable_story: true}))
           // console.log('storyboard activated');
            break;
  
          case 'ctrl+shift+d':
            // Handle Ctrl+S
            dispatch(updateSriptState({enable_story: false}))
            console.log('storyboard deactivated');
            break;
  
          default:
            break;
        }
      });
  
      // Unregister the keyboard shortcuts when the component is unmounted
      return () => {
        hotkeys.unbind('ctrl+shift+a,ctrl+shift+d');
      };
    }, []); // Empty dependency array to ensure the effect runs once on mount
  
   
    
    return <Box {...props} container className={classes.container} >
             {props.children}
       {/* { pendingCallReducer.loader_backdrop_open == true &&   <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(4px)', display:'flex', flexDirection:'column'}}
              open={userReducer.common_backdrop_open}
            >
                
                <CommonLoader/>
                <Typography sx={{fontSize:'50px', color:'white', fontWeight:600}}>Loading your project…</Typography>
          </Backdrop> */}
          <Footer />
          {/* <Typography sx={{fontSize:'12px', color:'#FFFFFF', width: '95%', textAlign: 'center', fontWeight:600, position: "absolute", bottom: '12px'}}>{"View our "} <Typography  component='span' onClick={()=> setOpenDisclaimerPopup(true)} sx={{fontSize:'12px', color:'#FFFFFF', fontWeight:900, cursor: 'pointer', textDecoration: 'underline #FFFFFF'}}>Disclaimer </Typography></Typography> */}
        {pendingCallReducer.loader_backdrop_open == true &&  <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
              open={pendingCallReducer.loader_backdrop_open}
            >
                <CommonLoader/>
                <Typography sx={{fontSize:'50px', color:'white', fontWeight:600}}>{pendingCallReducer.loader_text.title}</Typography>
                <Typography sx={{fontSize:'20px', color:'white'}}>{pendingCallReducer.loader_text.message}</Typography>
          </Backdrop>}
             {/* {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )} */}
          {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )}
        {userReducer.account_delete_popup == true && <DeleteConfirmationPopup />}
        {userReducer.footer_disclaimer && <DisclaimerPopup />}
           </Box>  


}

export default AuthLayout